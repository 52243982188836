import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonInput,
  IonText,
  IonLoading
} from '@ionic/react';
import React, { useState} from 'react';
import { Redirect } from 'react-router';
import { auth } from '../firebase';
import { useAuth } from '../auth';

// You need this interface to be able to pass properties to a React Function Component
interface Props {
  //loggedIn: boolean;
  onLogin: () => void;
}

// You need the interface above to pass in the onLogin function to the FC
const RegisterLoginPage: React.FC = () => {

  // Sign in variable hooks
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({error: false, loading: false});
  const { loggedIn } = useAuth();

  // Register function that checks with firebase whether credentials are correct
  const handleRegister = async () => {
    try {
      // loading
      setStatus({loading: true, error: false});

      // ADD MANAGEMENT EMAILS BELOW
      if (email === "jorgi@icloud.com" || email === "support@dplatinamerica.com") {
        const credential = await auth.createUserWithEmailAndPassword(email, password);
        console.log('registerLoginPage.ts | credential: ', credential);

        // finished loading and no error
        setStatus({loading: false, error: false});
        //console.log('RegisterLoginPage.ts | credential: ', credential);
      }
      else {
        setStatus({loading: false, error: true});
        //console.log("RegisterLoginPage.ts | error: unable to create a management account, please contact the administrator")
      }

    } catch (error) {
      // finished loading and error was caught
      setStatus({loading: false, error: true});
      //console.log("RegisterLoginPage.ts | error:", status.error)
    }

  };

  if (loggedIn) {
    return <Redirect to="/management/home/entries" />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Register Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            {/* we use the value parameter below to set the input text box to the email variable (initially it is blank) */}
            {/* logic below is as follows: every time I type, setEmail variable to what the input box holds, which is event.detail.value */}
            <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value!)}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput type="password" value={password} onIonChange={(event) => setPassword(event.detail.value!)}/>
          </IonItem>
        </IonList>
        { status.error && 
          <IonText color="danger">Registration failed: unable to create a management account, please contact the website administrator</IonText>
        }
        <IonButton expand="block" onClick={handleRegister}>Create Account</IonButton>
        <IonButton expand="block" fill="clear" routerLink="/management/login">
          Already have an account?
        </IonButton>
        {/* if we are loading then please show the IonLoading component */}
        <IonLoading isOpen={status.loading}></IonLoading>
      </IonContent>
    </IonPage>
  );
};

export default RegisterLoginPage;
