import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonInput,
  IonText,
  IonLoading
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { auth } from '../firebase';
import { useAuth } from '../auth';
import firebase from 'firebase/app';


// You need this interface to be able to pass properties to a React Function Component

// You need the interface above to pass in the onLogin function to the FC
const LoginPage: React.FC = () => {
  const { loggedIn } = useAuth(); //useAuth is returning the current context value => loggedIn: false

  // Sign in variable hooks
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({ error: false, loading: false });


  // Login function that checks with firebase whether credentials are correct
  const handleLogin = async () => {
    try {
      // loading
      setStatus({ loading: true, error: false });

      // log out after session ends
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          const credential = auth.signInWithEmailAndPassword(email, password);
          return credential
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('errorCode: ', errorCode);
          console.log('errorMessage: ', errorMessage);
        });

      //const credential = await auth.signInWithEmailAndPassword(email, password); // this is the only important line in this enture function

      // finished loading and no error
      setStatus({ loading: false, error: false });
      //console.log('loginPage.ts | credential: ', credential);

      //console.log("login page (before)| auth.loggedIn=", loggedIn);

    } catch (error) {
      // finished loading and error was caught
      setStatus({ loading: false, error: true });
      console.log("error:", status.error)
    }

  };

  if (loggedIn) {
    return <Redirect to="/management/home/entries" />;
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            {/* we use the value parameter below to set the input text box to the email variable (initially it is blank) */}
            {/* logic below is as follows: every time I type, setEmail variable to what the input box holds, which is event.detail.value */}
            <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value!)} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput type="password" value={password} onIonChange={(event) => setPassword(event.detail.value!)} />
          </IonItem>
        </IonList>
        {status.error &&
          <IonText color="danger">Invalid Credentials</IonText>
        }
        <IonButton expand="block" onClick={handleLogin}>Login</IonButton>
        <IonButton expand="block" fill="clear" routerLink="/management/register">
          Don't have an account?
        </IonButton>

        {/* if we are loading then please show the IonLoading component */}
        <IonLoading isOpen={status.loading}></IonLoading>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
