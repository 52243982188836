import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonLoading, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import WarrantyHomePage from './pages/WarrantyHomePage'
import WarrantyRegisterPage from './pages/WarrantyRegisterPage';
import RegisterLoginPage from './pages/RegisterLoginPage';



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// My custome styles sheet
import './theme/my-custom-styles.css';
import NotFoundPage from './pages/NotFoundPage';
import AppTabs from './AppTabs';
import LoginPage from './pages/LoginPage';
import { AuthContext, useAuthInit } from './auth';
import { auth } from './firebase';

import ReactGA from 'react-ga';
import AppConfig from './App.Config';

ReactGA.initialize( AppConfig.GOOGLE.GA_TRACKING_CODE );

auth.onAuthStateChanged((user) => {
  console.log('onAuthStateChanged:', user);

});

const App: React.FC = () => {

  // Authentication hook variables LOADING & LOGGEDIN
  const { loading, auth } = useAuthInit();

  console.log("app.ts | Rendering App with authState: ", auth);

  if (loading) {
    return <IonLoading isOpen />;
  };
  
  if (auth == null) {
    console.log("app.tsx | auth is null");
  }
  
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  return (
    <IonApp>
      <AuthContext.Provider value={auth!}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>

              {/* For clients */}
              <Route path="/warranty" component={WarrantyHomePage} exact={true} />
              <Route path="/warranty-register" component={WarrantyRegisterPage} exact={true} />

              {/* For management */}
              <Route path="/management/register" component={RegisterLoginPage} exact={true} />
              <Route path="/management/login" component={LoginPage} exact={true} />

              {/* within this path we have access to the management app */}
              <Route path="/management/home" component={AppTabs} exact={false} />

              <Route exact path="/" render={() => <Redirect to="/warranty" />} />
              <Route component={NotFoundPage} />

            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </AuthContext.Provider>
    </IonApp>
  )
};

export default App;
