import firebase from 'firebase/app';

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDlOh-VJNIahMLHq3zyke75fGrZaLm8pqY",
    authDomain: "dura-power.firebaseapp.com",
    databaseURL: "https://dura-power.firebaseio.com",
    projectId: "dura-power",
    storageBucket: "dura-power.appspot.com",
    messagingSenderId: "944086295206",
    appId: "1:944086295206:web:e5804055a9a8c77ff5ca67",
    measurementId: "G-0T0HE33B72"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// Initialize Auth service and export it
export const auth = app.auth();

// Initialize Firestore database
export const firestore = app.firestore();

// Initialize Firestore Storage 
export const storage = app.storage();

