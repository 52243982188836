//import firebase from 'firebase/app';

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React, { useEffect, useState, useContext } from 'react';
import { auth as firebaseAuth } from './firebase'

/////////////////////////////////////////////////////////
// The code below is used to get uid from anonymous user
export interface Auth {
    loggedIn: boolean;
    userId?: string;
}

interface AuthInit {
    loading: boolean;
    auth?: Auth; //if it's available  
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
    //console.log("AuthContext: ", AuthContext);
    return useContext(AuthContext);
}

// Auth Event Listener
export function useAuthInit(): AuthInit {
    
    const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });   //authInit will hold the uid

    useEffect(() => {
        firebaseAuth.onAuthStateChanged((firebaseUser) => {    //every time there is a change to the auth this executes
            //console.log("firebase.ts | currentUser: ", firebase.auth().currentUser);
            const auth = firebaseUser ? //if there is a user then return code below
                { loggedIn: true, userId: firebaseUser.uid } : // if TRUE
                { loggedIn: false }; // if FALSE
            setAuthInit({loading: false, auth});     
            //console.log("firebase.ts | loggedIn: ", auth);
        });
    }, []); //the empty array usually holds variables that we want the userEffect to listen to so that it executes every time it changes
            //having an empty array means we want the code to run just once
    //console.log("firebase.ts | currentUser: ", firebase.auth().currentUser);
    //console.log("firebase.ts | authInit: ", authInit);

    return authInit;
}
