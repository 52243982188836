import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonCard,
  IonItemGroup,
  IonCardContent,
  IonItem,
  IonCheckbox,
} from '@ionic/react';
// import { checkbox } from 'ionicons/icons';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { firestore } from '../firebase';

import { addMonths, Entry, toEntry } from '../models';

import { formatDate } from '../models';

interface RouteParams {
  outerDocID: string;
}

// function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
//   var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
//   return { width: srcWidth * ratio, height: srcHeight * ratio };
// }

// ENTRY PAGE CODE BELOW
const EntryPage: React.FC = () => {
  console.log('within entry page');
  const { outerDocID } = useParams<RouteParams>(); //useParams is a react function used to grab url paramaters
  const [entry, setEntry] = useState<Entry>();  //let TypeScript know the type of info we get from firestore is an Entry object
  //RouteParams is an interface we created above to tell useParams that it can only accept id strings

  //the code below fetches document info from firestore
  useEffect(() => {

    async function run() {
      const entryRef = firestore.collection('users').doc(outerDocID).collection('entries'); //this returns a single document (data point) withing a collection
      const docs = await entryRef.get()
      docs.forEach((doc) => {
        setEntry(toEntry(outerDocID, doc)); //the three dots expand the data we have in the firestore document object; the "as Entry" let's TS know that the info inside doc.data() will be in the correct format
      });
    }
    run();
    console.log("AFTER RUN");
  }, [outerDocID]);




  const check = async () => {
    const snapshot = await firestore.collection('users').doc(outerDocID).collection('entries').get(); //this returns a single document (data point) withing a collection
    snapshot.forEach(async (doc) => { // iterate through each doc 
      const docId = doc.id;
      const docRef = await firestore.collection('users').doc(outerDocID).collection('entries').doc(docId);
      if (entry?.warranty_boolean === false) {
        docRef.update({
          warranty_boolean: true
        }).then(function () {
          console.log("Document successfully updated!");
        }).catch(function (error: any) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      } else {
          docRef.update({
            warranty_boolean: false
          }).then(function () {
            console.log("Document successfully updated!");
          }).catch(function (error: any) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    });
  }

  // the code below handles the deletion of a document
  // const handleDelete = async () => {
  //   const entryRef = firestore.collection('users').doc(outerDocID).collection('entries'); //this returns a single document (data point) withing a collection
  //   const docs = await entryRef.get();
  //   docs.forEach(async (doc) => {
  //     let doc_to_delete = await doc.data(); //returns a Promise so use async above
  //     await doc_to_delete.delete();
  //   }); // iterate through firebase documents for a single user registration
  //   history.goBack();
  // }

  // the code below handles image sizing (it doesn't work)
  // const [imgPropWidth, setImgPropWidth] = useState();
  // const [imgPropHeight, setImgPropHeight] = useState();

  // const img = new Image();
  // img.onload = function () {
  //   const srcWidth = img.width;
  //   const srcHeight = img.height;
  //   var finalWidth: any, finalHeight: any = calculateAspectRatioFit(srcWidth, srcHeight, 50, 50)
  //   setImgPropWidth(finalWidth);
  //   setImgPropHeight(finalHeight);
  // }
  // img.src = entry?.pictureURL;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end" className="ion-padding">
            {/* <IonIcon icon={trashIcon} slot="icon-only" onClick={handleDelete} size="medium" /> */}
          </IonButtons>
          {/* important! add the '?' below if not you will get an error. initially, entry is null, that is until we get the data from firestore */}
          <IonTitle>{formatDate(entry?.date!)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-wrap">
        <IonItemGroup>
          <IonCard className="light">
            <IonCardContent>
              <IonLabel className="light ion-text-wrap">
                <h1><b>Warranty Registration</b></h1> <br />
                <h2><b>Email: </b>{entry?.email}</h2>
                <h2><b>Date of Purchase: </b>{formatDate(entry?.date!)}</h2>
                <h2><b>Battery Batch Number: </b>{entry?.batchNo!}</h2>
                <h2><b>Purchase Location: </b>{entry?.location!}</h2>
                <h2><b>Warranty Expiration Date: </b>{addMonths(entry?.date!)}</h2>
              </IonLabel>
              <IonItem>
                <IonLabel>Warranty has been claimed </IonLabel>
                <IonCheckbox slot="start" color="danger" checked={entry?.warranty_boolean} onClick={check}/>
              </IonItem>

            </IonCardContent>

          </IonCard>

        </IonItemGroup>
        <IonCard>
          <img src={entry?.pictureURL} alt={entry?.email} className="image2 center" />
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default EntryPage;
