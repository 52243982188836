import { Camera, CameraResultType, CameraSource } from '@capacitor/core';
import { IonBackButton, IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTitle, IonToolbar, isPlatform, IonToast } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { firestore } from '../firebase';
import firebase from 'firebase/app';
import { savePicture } from '../storage';
import { useAuth } from '../auth';

// MAIN WARRANTY REGISTER PAGE FUNCTION COMPONENT
const WarrantyRegisterPage: React.FC = () => {

  // State Variable Hooks
  const [date, setDate] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [warranty_boolean, setWarranty_boolean] = useState<boolean>(false);

  const [batchNo, setBatchNo] = useState('');
  const [pictureURL, setPictureURL] = useState('../assets/yellow_upload.png');
  const [status, setStatus] = useState({ error: false, loading: false });
  const { userId } = useAuth(); // call our function in firebase.ts that gets the anonymous userId
  const [showToast, setShowToast] = useState(false); // let the user know registration was successful


  // Additional Variables
  const fileInputRef: any = useRef<HTMLInputElement>();
  const history = useHistory(); // this variable is later used to go back to the home page

  useEffect(() => () => { //this syntax with () => () means we return a function
    URL.revokeObjectURL(pictureURL);
    //console.log("revokedURL: ", pictureURL)
  }, [pictureURL]); //whenever the pictureURL changes call the React useEffect function to update 

  /////////////////////////////////////////////////////////////////////////////////////////////
  // function below is called when we click 'save' - the function saves data to the firestore!!
  const handleSave = async () => {  //we use the "async" to use the "await" below - this is needed when handling Promises 

    const docRef = await firestore.collection('users').add({ 'uid': userId });     // initialize userID document; this is overwritten with line below; we use this to generate the document ID
    firestore.collection('users').doc(docRef.id).set({ 'userId': userId, 'docId': docRef.id });
    const entriesRef = firestore.collection('users').doc(docRef.id).collection('entries'); //location where we want to save

    // if data form is complete then proceed
    if (date === '' || email === '' || batchNo === '' || pictureURL === '../assets/yellow_upload.png' || location === '') {
      setStatus({ error: true, loading: false });
    } else {

      const entryData = { userId, date, email, batchNo, location, warranty_boolean, pictureURL }; //data we want to save

      // if statement below handles saving to firebase storage and loading
      if (!pictureURL.startsWith('/assets')) { //if we have an image that is not the one we have in our assets folder then proceed
        try {
          setStatus({ loading: true, error: false }); // loading
          entryData.pictureURL = await savePicture(pictureURL, userId); // SAVE IMAGE TO FIREBASE STORAGE - note: the pictureURL is a blobURL
          setStatus({ loading: false, error: false }); // finished loading and no error
        } catch (error) {
          setStatus({ loading: false, error: true }); // finished loading and error was caught
          console.log("WarrantyRegisterPage.ts | error:", status.error)
        }
      }

      // HERE WE ADD THE DATA TO THE FIRBASE FIRESTORE DATABASE
      const entryRef = await entriesRef.add(entryData); //add the data
      console.log('WarrantyRegisterPage.ts | saved: ', entryRef.id);  //print the id of the document where we saved the data

      setStatus({ error: false, loading: false }); // show loading while we save the data
      setShowToast(true);
      await delay(7000);
      firebase.auth().signOut();  // sign out the anonymous user 
      history.goBack(); //go back to previous screen
    }
  };

  /////////////////////////////////////////
  const handlePictureClick = async () => {
    // the isPlatform code below is only for native camera access (not currently being used)
    console.log("PICTURE CLICKED")
    if (isPlatform('capacitor')) {
      try {
        const photo = await Camera.getPhoto({ //returns a Promise of a photo Uri (basically a local url)
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera //opens the camera directly - if you want to ask the user write "Prompt"
        });
        console.log('photo:', photo.webPath);
        setPictureURL(photo.webPath!);
      } catch (error) {
        console.log("Camera error: ", error);
      }
    } else {
      fileInputRef.current?.click(); // this calls the handleFileChange function
    }
  };

  /////////////////////////////////////////////////////////////
  //function below first handles the raw image the user uploads - basically saves a local BLOB pictureURL 
  const handleFileChange = (event: any) => {
    console.log("file: ", event.target.files.item(0));
    if (event.target.files.length > 0) { //if we have files
      const file = event.target.files.item(0) //we access the file the user selected



      const pictureURL = URL.createObjectURL(file)  //we convert the file into an image url locally
      console.log("pictureURL: ", pictureURL)
      setPictureURL(pictureURL);
    }
  };

  ///////////////////////////////////////////////////////////
  // delay function below
  function delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  return (
    <IonPage>

      <IonHeader>

        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="ion-text-wrap" >Warranty Registration</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="dark">
        <IonGrid fixed={true}>
          <IonItem color="dark">
            <IonLabel position="stacked" style={{ color: "#F1D549" }}>Date of Purchase</IonLabel>
            <IonInput type="date" value={date} onIonChange={(event) => setDate(event.detail.value!)} placeholder="Jan 1, 2021" />
          </IonItem>

          <IonItem color="dark">
            <IonLabel position="stacked" style={{ color: "#F1D549" }}>Email</IonLabel>
            <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value!)} placeholder="email@address.com" />
          </IonItem>

          <IonItem color="dark">
            <IonLabel position="stacked" style={{ color: "#F1D549" }}>Battery Batch No.</IonLabel>
            <IonInput type="text" value={batchNo} onIonChange={(event) => setBatchNo(event.detail.value!)} placeholder="e.g. ABC123DEF456" />
          </IonItem>

          <IonItem color="dark">
            <IonLabel position="stacked" style={{ color: "#F1D549" }}>Location of Purchase</IonLabel>
            <IonInput type="text" value={location} onIonChange={(event) => setLocation(event.detail.value!)} placeholder="e.g. Puerto Rico Auto Parts" />
          </IonItem>

          <IonItem color="dark">
            <IonLabel position="stacked" className="ion-text-wrap"><p style={{ color: "#F1D549" }}>Picture of Receipt</p> <br /> <p><i>Please validate date of purchase by uploading your purchase receipt.</i></p> <br /> </IonLabel> <br />
            {/* <IonLabel position="stacked" className="ion-text-wrap"> <br /> </IonLabel> <br /> */}
            <input type="file" hidden={true} accept="image/*" ref={fileInputRef} onChange={(event) => handleFileChange(event)} />
            {/* <img src={pictureURL} alt="" onClick={() => fileInputRef.current.click()} style={{ cursor: "pointer" }} /> */}
            <img src={pictureURL} alt="" style={{ cursor: 'pointer' }} onClick={handlePictureClick} className="image" /> <br />
          </IonItem>


          {/* If there is missing information show text below */}
          {status.error &&
            <IonItem color="dark">
              <IonText color="danger" className="ion-padding textMod">
                <p><i>Invalid Form: Please make sure you completed all fields above correctly.</i></p>
              </IonText>
            </IonItem>

          }

          <IonButton className="ion-padding round" expand="block" onClick={handleSave} color="warning"><b>Register</b></IonButton>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Your registration has been successful. You will receive an email confirmation soon. Check your spam/junk folder."
            duration={6000}
          />

          <IonLoading isOpen={status.loading}></IonLoading>
        </IonGrid>
      </IonContent>



    </IonPage>
  );
};

export default WarrantyRegisterPage;
