import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import React from 'react';

import { home as homeIcon, settings as settingsIcon } from 'ionicons/icons';

import ManagementHome from './pages/ManagementHome';
import SettingsPage from './pages/SettingsPage';
import { useAuth } from './auth';
import EntryPage from './pages/EntryPage';

//import EntryPage from './pages/EntryPage';

interface Props {
  loggedIn: boolean;
}

const AppTabs: React.FC = () => {
  // IMPORTANT: before enetering the AppTabs, check whether you're logged in using useAuth()

  const { loggedIn } = useAuth();
  console.log("appTabs.ts | loggedin: ", loggedIn);
  if (!loggedIn) {
    return <Redirect to="/management/login" />;
  }
  return (

    <IonTabs>

      <IonRouterOutlet>
        {/* First route path */}
        <Route path="/management/home/entries" component={ManagementHome} exact={true} />

        {/* <Route exact path="/management/home/entries">
          <WarrantyHomePage />
        </Route> */}


        {/* Second route path */}
        <Route exact path="/management/home/settings">
          <SettingsPage />
        </Route>

        {/* Third route path */}
        <Route exact path="/management/home/entries/view/:outerDocID">
          <EntryPage />
        </Route>

      </IonRouterOutlet>

      {/* Tab Bar Below */}
      <IonTabBar slot="bottom">

        {/* Home Tab Button */}
        <IonTabButton tab="home" href="/management/home/entries">
          <IonIcon icon={homeIcon} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>

        {/* Settings Tab Button */}
        <IonTabButton tab="settings" href="/management/home/settings">
          <IonIcon icon={settingsIcon} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>

      </IonTabBar>

    </IonTabs>
  );
};

export default AppTabs;
