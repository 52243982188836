import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { auth } from '../firebase';

// Adding Google Analytics
import ReactGA from 'react-ga';
// import AppConfig from '../App.Config';

const WarrantyHomePage: React.FC = () => {

  // Anonymous login
  const handleAnonymousLogin = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked button: Register Warranty'
    });
    auth.signInAnonymously();
  }

  return (
    <IonPage color="dark">

      <IonHeader>
        <IonToolbar color="dark">
          <IonTitle className="ion-text-wrap">Dura Power &#x2122; </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="dark">
        <IonGrid fixed={true}>

          {/* Ion Card */}
          <IonCard color="medium" className="ion-center">
            <img src="../assets/fb-dp-marketing-content.jpg" alt="" className="" />
            <IonCardHeader className="textCenter">
              <IonCardSubtitle><h5><b>Welcome!</b></h5></IonCardSubtitle>
              <IonCardTitle style={{ color: "#f1c84b" }}>Dura Power Warranty Registration</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="textCenter">
              <p>Dear customer, congratulations on your choice of Dura Power battery.</p> <br />
              <p>Duration of warranty is 18 months since the date of purchase. This warranty commitment does not cover any claim that is beyond our warranty limit.</p> 

              <IonButton className="button-fit ion-padding round" color="warning" expand="block" onClick={handleAnonymousLogin} routerLink="/warranty-register"><b>Register Battery Warranty</b></IonButton> <br /> <br />
              <br />
              <p>For support or warranty claim please email us at <b>jgonzalez@oleincorp.com</b></p>
            </IonCardContent>


            <IonItem color="dark">
              <IonLabel><p>Lumino Apps &#x000A9; 2020-2021. All Rights Reserved. </p></IonLabel>
            </IonItem>

          </IonCard>

        </IonGrid>
      </IonContent>

    </IonPage>
  );
};

export default WarrantyHomePage;
