import firebase from 'firebase/app';

export interface Entry {
    outerDocID: string;
    id: string;
    userId: string;
    date: string;
    email: string;
    batchNo: string;
    location: string;
    warranty_boolean: boolean;
    pictureURL: string;
}

export function toEntry(outerDocID: string, doc: firebase.firestore.DocumentData): Entry {  //returns an Entry type
    const entry = { ...doc.data() as Entry };
    entry.outerDocID = outerDocID;
    return entry;
}

// export function toUsers(doc: any): Entry {  //returns an Entry type
//     return { doc.data() as Entry};
// }

export function formatDate(isoString: string) {
    console.log('isostring of format date ', isoString);

    return new Date(isoString).toLocaleDateString('en-US', {
        day: "numeric", month: "short", year: "numeric"
    });
}

export function addMonths(isoString: string) {
    console.log('isostring: ', isoString);
    // const date = new Date(isoString).toLocaleDateString('en-US', {
    //         day: "numeric", month: "short", year: "numeric"
    //       });
    const dateObject = new Date(isoString);
    console.log('date type: ', dateObject);

    var d = dateObject.getDate();
    console.log('dateObject getDate: ', d);

    dateObject.setMonth(dateObject.getMonth() + +18);
    if (dateObject.getDate() !== d) {
        dateObject.setDate(0);
    }
    console.log('dateObject set month: ', dateObject);

    //  let date = new Date(dateObject.toISOString());
    //  console.log('date: ', date);
    let year = dateObject.getFullYear();
    console.log('year: ', year);
    let month = dateObject.getMonth() + 1;
    let dt = dateObject.getDate();

    if (dt < 10) {
        dt = parseInt('0' + dt.toString());
    }
    if (month < 10) {
        month = parseInt('0' + month.toString());
    }
    let dateString = year.toString() + '-' + month.toString() + '-' + dt.toString();
    console.log(dateString);
    let final_date = formatDate(dateString);
    console.log(final_date);
    return final_date;
}
