import { IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSearchbar, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { search as searchIcon } from 'ionicons/icons';

import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { Entry, formatDate, toEntry } from '../models';
//import { savePicture } from '../storage';
//import { Entry, formatDate, toEntry } from '../models';
import './ManagementHome.css';

const ManagementHome: React.FC = () => {

  const [tempSearchText, setTempSearchText] = useState('');
  const [count, setCount] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [entries, setEntries] = useState<Entry[]>([]);  // a list of entries 
  const [status, setStatus] = useState({ error: false, loading: false });


  // useEffect() - all the things React needs to render 
  useEffect(() => {
    // instantiate variables
    const uid_list: string[] = [];
    const doc_list: string[] = [];
    let entries_all: Entry[] = [];
    let search_entries_all: Entry[] = [];
    let match: Boolean = false;



    async function run() {

      setStatus({ error: false, loading: true }); //show loading bar

      // create uid_list and docID_list
      try {
        const usersRef = firestore.collection('users'); // firebase 'users' path
        const snapshot = await usersRef.get();  // firebase snapshot of documents within 'users' path
        snapshot.forEach((doc) => { // iterate through each doc 
          const temp = doc.data();
          uid_list.push(temp['userId']);  // save all my userId
          doc_list.push(temp['docId']); // save all my docId
        });
      } catch (error) {
        setStatus({ loading: false, error: true }); // finished loading and error was caught
        console.log("WarrantyRegisterPage.ts | error:", status.error)
      }

      
      // use the docID_list to query all the info within each doc
      console.log('doc_list: ', doc_list);
      for (let index in doc_list) {
        await getEntries(doc_list[index], entries_all); // save the user info into entries_all list
      }
      setEntries(entries_all.slice(0,20));  //print only the first 20 warranty registrations to speed up performance 
      console.log("entries_all", entries_all);
      setCount(entries_all.length);
      ////////////////////////////////
      // Search bar re rendering - update the entries with only the matches
      if (searchText !== '') {

        for (let index in entries_all) {

          // check if there is an email match
          if (entries_all[index]['email'] === searchText) {
            match = true;
            search_entries_all.push(entries_all[index]);
          }

          // check if there is a batch number match
          if (entries_all[index]['batchNo'] === searchText) {
            match = true;
            search_entries_all.push(entries_all[index]);
          }
        }
      }
      console.log('matches= ', search_entries_all);

      // update entries_all to only reflect the ones that match our search
      if (match) {
        setEntries(search_entries_all);
        match = false;
      }
      setStatus({ error: false, loading: false });
    }
    run(); //RUN MY USEEFFECT FUNCTION ABOVE

  }, [searchText, status]);

  // use this function to iterate high level document IDs and then iterate low level entries
  //returns a list of entries_all [{userId: '', email: '', ...}, {}...]
  async function getEntries(docID: string, entries_all: Entry[]) {
    const entriesRef = firestore.collection('users').doc(docID).collection('entries'); //this line returns a collection reference
    const docs = await entriesRef.get();
    docs.forEach((doc) => {
      console.log('DOC HERE: EMAIL: ', doc.data().email)
      let entry = toEntry(docID, doc);
      entries_all.push(entry);
    }); // iterate through firebase documents for a single user registration
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Dura Power</IonTitle>
          <IonButtons slot="end" className="ion-padding pointer">
            <IonIcon icon={searchIcon} slot="icon-only" onClick={() => setSearchText(tempSearchText.toLowerCase())} size="medium" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonLabel> Count: {count}</IonLabel>
        <IonSearchbar value={tempSearchText} onIonChange={e => setTempSearchText(e.detail.value!)} placeholder="Search by email or batch number"></IonSearchbar>
        <IonList>
          {entries.map((entry) =>
            <IonItem button key={entry.outerDocID} routerLink={`entries/view/${entry.outerDocID}`}>
              <IonLabel>
                <h1>{formatDate(entry.date)}</h1> <br />
                <h3>Batch No: {entry.batchNo}</h3>
                <h3>Email: {entry.email}</h3>
              </IonLabel>
              <IonThumbnail slot="end">
                <img src={entry?.pictureURL} alt={entry.email} />
              </IonThumbnail>
            </IonItem>
          )}
        </IonList>

        {/* If there is missing information show text below */}
        {status.error &&
          <IonItem color="dark">
            <IonText color="danger" className="ion-padding textMod">
              <p><i>Please log out and reauthenticate.</i></p>
            </IonText>
          </IonItem>
        }
        <IonLoading isOpen={status.loading}></IonLoading>
      </IonContent>
    </IonPage>
  );
};
export default ManagementHome;
