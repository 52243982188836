import { storage } from './firebase';

///////////////////////////////////////////////////////////////
// the function below saves a picture to firebase cloud storage
export const savePicture = async (blobURL: any, userId: any) => {
    const pictureRef = storage.ref(`/users/${userId}/pictures/${Date.now()}`); // this is the path where we want to save the image
    const response = await fetch(blobURL);  // we use await because it's a promise - it can either succeed or fail - fetch is like curl in unix
    const blob = await response.blob(); //this returns a blob object
    const snapshot = await pictureRef.put(blob);  // here we upload blob into the pictureRef path - this will be the data uploaded to firebase and it returns a snapshot
    const url = await snapshot.ref.getDownloadURL();  // here we download the URL of the actual picture stored in the storage
    console.log('saved picture successfully')
    return url
};
